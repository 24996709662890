import React from "react";
import ExchangeCard from "./ExchangeCard";

export default function ExchangeList({
  currencies,
  exchanges,
  onDeleteExchange,
}) {
  //definimos las props que vamos a pasar al hijo

  //vamos a mapear nuestros exchanges asignando un key para cada uno y pasando las props del padre
  return (
    <div className="exchange-list">
      {exchanges.map((exchange) => (
        <ExchangeCard
          key={exchange.id}
          currencies={currencies}
          exchange={exchange}
          onDeleteExchange={onDeleteExchange}
        />
      ))}
    </div>
  );
}

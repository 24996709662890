import React from "react";
import "./ExchangeList.css";

export default function ExchangeItem({
  currencies,
  exchange,
  onDeleteExchange,
}) {
  //definimos las props
  //funcion para eliminar que llama a la funcion de nos han pasado pasando el id
  const handleDeleteExchange = () => {
    onDeleteExchange(exchange.id);
  };

  //para seleccionar el src de cada moneda selecionaremos de los currencies el exchange concreto para acceder a su flag

  //en el excahnge-item__amount tenemos la conversion de moneda que va a dividir la cantidad
  //entre el rate de la moneda origen y despues multiplicarlo por el rate de la moneda destino
  //luego usamos toFixed para dejar solo 2 decimales

  //La imagen con el icono de eliminar sera la que llame en el OnClick a la funcion de eliminar
  return (
    <div className="exchange-item row">
      <div className="mt-10">
        <img
          alt={currencies[exchange.codOrigen].name}
          src={"/img/flags/" + currencies[exchange.codOrigen].flag}
          width={80}
        />
        <p className="exchange-item__amount">
          {exchange.amount + " " + exchange.codOrigen}
        </p>
      </div>
      <div className="mt-10">
        <img src="/flecha.png" alt="" width={80} />
      </div>
      <div className="mt-10 mr-10">
        <img
          alt={currencies[exchange.codDest].name}
          src={"/img/flags/" + currencies[exchange.codDest].flag}
          width={80}
        />
        <p className="exchange-item__amount">
          {(
            (exchange.amount / currencies[exchange.codOrigen].exchangeRate) *
            currencies[exchange.codDest].exchangeRate
          ).toFixed(2) +
            " " +
            exchange.codDest}
        </p>
      </div>
      <div className="exchange-item__delete">
        <img
          src="/delete.png "
          alt=""
          width={40}
          onClick={handleDeleteExchange}
        />
      </div>
    </div>
  );
}

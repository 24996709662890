import React, { useState } from "react";
import "./InsertItem.css";

const CurrencyComboBox = ({ currencies, onSelectCurrency, label }) => {
  //Gestionamos estados para tener la moneda selecionada y para saber si tenemos que mostrar todas las currencies o no
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  //con esta funcion vamos a cambiar los estados tanto de la moneda seleccionada en el combobox como en el insert
  //tambien gestionamos el enseñar las currencies
  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency);
    onSelectCurrency(currency);
    setShowOptions(false);
  };

  //la funcion de on click sera la que cambie si enseñar todos o no

  //cuando tengamos que mostrarlos mapearemos los currencis
  return (
    <div>
      <label>{label}</label>

      <div className="comboBox" onClick={() => setShowOptions(!showOptions)}>
        {selectedCurrency ? (
          <>
            <img
              src={`/img/flags/${currencies[selectedCurrency].flag}`}
              alt={selectedCurrency}
              style={{ marginRight: "5px", width: "25px", height: "18px" }}
            />
            {currencies[selectedCurrency].name}
          </>
        ) : (
          "Select a Currency"
        )}
      </div>

      {showOptions && (
        <div
          style={{
            border: "1px solid #d27c2c",
            marginTop: "5px",
            position: "absolute",
            zIndex: "1",
            backgroundColor: "#fff",
          }}
        >
          {Object.keys(currencies).map((currencyCode) => (
            <div
              key={currencyCode}
              onClick={() => handleCurrencyClick(currencyCode)}
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "1px solid #d27c2c",
              }}
            >
              <img
                src={`/img/flags/${currencies[currencyCode].flag}`}
                alt={currencyCode}
                style={{ marginRight: "5px", width: "25px", height: "18px" }}
              />
              {currencies[currencyCode].name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CurrencyComboBox;

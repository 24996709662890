import React, { useState } from "react";
import CurrencyComboBox from "./CurrencyComboBox";
import "./InsertItem.css";

export default function InsertItem({ currencies, onNewExchange }) {
  //Necesitamos tres hooks de estado, el de las monedas  de origin y destino y la cantidad
  const [selectedOriginCurrency, setSelectedOriginCurrency] = useState(null);
  const [selectedDestinationCurrency, setSelectedDestinationCurrency] =
    useState(null);
  const [amount, setAmount] = useState("");

  //funcion para cambiar la moneda origen, la pasaremos al combobox que sera quien lo llame
  const handleSelectOriginCurrency = (currency) => {
    setSelectedOriginCurrency(currency);
  };

  //funcion para cambiar la moneda destino, la pasaremos al combobox que sera quien lo llame
  const handleSelectDestinationCurrency = (currency) => {
    setSelectedDestinationCurrency(currency);
  };

  //Funcion para controlar al añadir unn nuevo exchange
  const handleAgregarCambio = () => {
    //expresion regular para comprobar que el input cantidad no lleve letras
    let regex = /^[0-9]+$/;

    //comprobamos la cantiad y que tengamos tanto monde origen como destino
    if (
      regex.test(amount) &&
      selectedOriginCurrency &&
      selectedDestinationCurrency
    ) {
      // Crear objeto con la información del cambio
      const newExchangeObject = {
        id: Date.now(),
        amount: amount,
        codOrigen: selectedOriginCurrency,
        codDest: selectedDestinationCurrency,
      };
      // Llamar a la función proporcionada por el padre para agregar el cambio
      onNewExchange(newExchangeObject);
      // Limpiar los campos después de agregar el cambio
      setAmount("");
    } else {
      //Si no se cumple la sondiciones le avisamos al usuario
      alert("Rellena todos los campos de forma correcta");
    }
  };
  //El input de amount tendra una funcion en el onChange que se encargara de cambiar el estado de amount
  //que cambiara tambien su valor pornpantalla ya que su valor depende del estado de amount

  //A los combobox les pasaremos los currencies para que los muestren al pinchar,
  // ademas del label para diferenciar entre origen y destino
  //Pasaremos la funcion de callback para cambiar el estado de la moneda de origen y destino seleccionada

  //En el input de add tendra a funcion para añadir en el onClick
  return (
    <div className="d-flex">
      <div className="money">
        <img src="/money.png" alt="" width={150} />
      </div>
      <div className="insert-item">
        <h1 className="insert-item__h1">Currency Exchanger</h1>
        <div className="insert-item__row">
          <div className="insert-item__amount">
            <label htmlFor="amount" className="insert-item__label-amount">
              Amount:
            </label>
            <input
              type="text"
              min="1"
              placeholder="0"
              value={amount}
              className="insert-item__amount-input"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className="insert-item__comboBox">
            <CurrencyComboBox
              currencies={currencies}
              onSelectCurrency={handleSelectOriginCurrency}
              label={"Origin Currency:"}
            />
          </div>
          <div className="insert-item__flecha">
            <img src="/flechaUnica.svg" alt="" width={50}></img>
          </div>
          <div className="insert-item__comboBox">
            <CurrencyComboBox
              currencies={currencies}
              onSelectCurrency={handleSelectDestinationCurrency}
              label="Destination currency:"
            />
          </div>
          <div className="insert-item__add">
            <input
              type="submit"
              name="add"
              value="Add"
              className="insert-item__input"
              onClick={handleAgregarCambio}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
}
